body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Ubuntu', 'Roboto', 'Oxygen',
    'Segoe UI', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:black;
  color:white;
}

@font-face {
  font-family: Ancient;
  src: url('./AncientMedium.ttf');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size:128px;
  margin: auto;
  display:inline-block;
}

h3 {
  font-size:48px;
  margin:auto;
}

h1, h3 {
  font-family:'Ancient', serif;
}

h2, h4, h5, h6 {
  letter-spacing: 2px;
  margin:auto;
}

h2{
  font-size:small;
  font-weight:normal;
  text-transform: uppercase;
}

#navigation a{
  display:inline-block;
  margin-right:2.5rem;
  font-size:small;
  letter-spacing:1px;
  text-decoration:none;
  color:white;
  letter-spacing: 2px;
}

.App {
  margin:15px auto 15px auto;
  width:60%;
  border:1px solid white;
  padding:0.25rem 2rem 15px 2rem;
  display:flexbox;
}

main {
  margin-top:3rem;
}

.twitter-tweet{
  width:75%;
  margin-left:auto;
  margin-right:auto;
  border-left:1px solid white;
  padding-left:1em;
}

.App, main {
  animation: fadeInAnimation ease 1s
}

a {
  text-decoration: none;
  color: hsl(103, 100%, 83%);
}

h1 {
  color: white;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}

html{
   background-color: black;
}

main li {
  list-style-image: url(../public/bullet.png);
}

main ul {
  margin-bottom:15px;
}

.bingoTable{
  margin-right:auto;
  margin-left:auto;
  text-align:center;
  border-collapse:collapse;
}

th{
  padding-bottom:10px;
}

.bingoTable td{
  width:100px;
  height:100px;
  border:1px solid white;
}

.refresh {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top:15px;
}

button {
  border: 2px outset #000;
  font-size: initial;
  background: transparent;
  color: white;
  padding: 5px;
}

.review-content {
  display: flex;
  gap: 15px;
  align-items: center;
}
.review-wrapper:nth-child(odd){
  animation: 3.5s slidein;
}
.review-wrapper:nth-child(even){
  animation: 3.5s slidein-left;
}
@keyframes slidein {
  from {
      translate: 30px 0;
      opacity: 0%;

  }
  to {
      translate: 0 0;
      opacity: 100%;
  }
}
@keyframes slidein-left {
  from {
      translate: -30px 0;
      opacity: 0%;

  }
  to {
      translate: 0 0;
      opacity: 100%;
  }
}
.review-wrapper:nth-child(odd) .review-content {
  flex-direction: row-reverse;
}
.review-image img {
  height: auto;
  max-width: 200px;
  box-shadow: 0 5px 5px black;
}
.review-text {
  text-align: justify;
}

@media screen and (max-width: 880px){
  .App{
    border:none;
    width:80%;
    margin-left:auto;
    margin-right:auto;
    padding:none;
  }
  h1{
    font-size:5rem;
  }
  #navigation a {
    margin-top: 1rem;
  }
  .bingoTable{
    table-layout: fixed;
    margin-right:auto;
    margin-left:auto;
    width:80vw;
  }
  .bingoTable td{
    font-size: 10px;
  }
  .itchFrame{
    height:50vw;
    width:78vw;
  }
  .bingoTable td{
    font-size:1vw;
  }
  .itchFrame{
    height:180px;
    width:600px;
  }
  .review-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
  }
  .review-wrapper:nth-child(odd) .review-content {
    flex-direction: column;
  }
}