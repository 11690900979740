@media (prefers-color-scheme: light){
    html, body {
        background-color: hsl(0, 0%, 90%);
        color: #000;
    }
    h1, #navigation a {
        color: #000;
    }
    a {
        color: hsl(103, 100%, 23%);
    }
    .App, .twitter-tweet, .bingoTable td {
        border-color: #000;
    }
    button {
        color: #000;
        border-color: #fff;
    }
    main li {
        list-style-image: url(../public/bullet-dark.png);
      }
}